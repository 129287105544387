
import { Component, Vue } from 'vue-property-decorator'
import { Action, Getter, namespace } from 'vuex-class'

const statistics = namespace('statistics')
const items = namespace('statistics/items')
const properties = namespace('statistics/properties')

@Component
export default class Status extends Vue {
  @statistics.Getter private dumpTimestamp!: number
  @statistics.Getter private classesTimestamp!: number
  @statistics.Getter private propertiesTimestamp!: number
  @statistics.Getter private lastRefresh!: number
  @statistics.Getter private siteLinkCount!: number
  @items.Getter('count') private itemCount!: number
  @items.Getter('countLabels') private itemLabels!: number
  @items.Getter('countStatements') private itemStatements!: number
  @items.Getter('countDescriptions') private itemDescriptions!: number
  @items.Getter('countAliases') private itemAliases!: number
  @properties.Getter('count') private propertyCount!: number
  @properties.Getter('countLabels') private propertyLabels!: number
  @properties.Getter('countStatements') private propertyStatements!: number
  @properties.Getter('countDescriptions') private propertyDescriptions!: number
  @properties.Getter('countAliases') private propertyAliases!: number
}
